import React from 'react';
import {
  Container,
  // Collapse,
  Navbar,
  // NavbarToggler
} from 'reactstrap';

// import styled from "styled-components"

import Link from './link'
// import Navigation from 'reactstrap-json-nav'
// import navigation from '../data/navigation';
// import logo from '../assets/images/logo.svg'


// let StyledNavbar = styled(Navbar)`
//   position: absolute;
//   border-bottom: 1px solid ${props => props.theme.gray200};
//   background-color: white;
//   @include sans-serif-font;
//   @media only screen and (min-width: 992px) {
//     border-bottom: 1px solid transparent;
//     background: transparent;
//   }
//   @media only screen and (min-width: 992px) {
//     -webkit-transition: background-color 0.2s;
//     -moz-transition: background-color 0.2s;
//     transition: background-color 0.2s;
//     /* Force Hardware Acceleration in WebKit */
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: translate3d(0, 0, 0);
//     -ms-transform: translate3d(0, 0, 0);
//     -o-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//     -webkit-backface-visibility: hidden;
//     backface-visibility: hidden;
//   }
// `

// let StyledNavbarBrand = styled(Link)`
//   font-weight: 800;
//   color: ${props => props.theme.gray800};
//   @media only screen and (min-width: 992px) {
//     padding: 10px 20px;
//     color: ${props => props.theme.white};
//     &:focus,
//     &:hover {
//       color: fade-out(${props => props.theme.white}, .2);
//     }
//   }
// `


const Header = (props) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);

  const scrollDown = (e) => {
    window.scrollTo(0,document.body.scrollHeight); 
    e.preventDefault();
  }

  return (
    <Navbar color="light" light fixed="true" expand="md">
      <Container>
        <Link to="/" className="navbar-brand">
          {/* <img src={logo} alt="Logo" height="25px"/> */}
          <i>Ina</i>rticulately
        </Link>
        <a href="#" className="navbar-text small" style={{textDecoration:"none"}} onClick={scrollDown}>About Me</a>
        {/* <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Navigation
            json={navigation}
            link={Link}
            activeClassName="active"
          />
        </Collapse> */}
      </Container>
    </Navbar>
  );
}

export default Header;