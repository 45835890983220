let theme = {
    white:    '#fff',
    gray100: '#f8f9fa',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#ced4da',
    gray500: '#adb5bd',
    gray600: '#868e96',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
    black:    '#000',
    
    blue:    '#007bff',
    indigo:  '#6610f2',
    purple:  '#6f42c1',
    pink:    '#e83e8c',
    red:     '#dc3545',
    orange:  '#fd7e14',
    yellow:  '#ffc107',
    green:   '#28a745',
    teal:    '#0085A1',
    cyan:    '#17a2b8',    
}


theme['primary'] = theme['teal']
theme['secondary'] = theme['gray600']
theme['success'] = theme['green']
theme['info'] = theme['cyan']
theme['warning'] = theme['yellow']
theme['danger'] = theme['red']
theme['light'] = theme['gray100']
theme['dark'] = theme['gray800']
  
export default theme