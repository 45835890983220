import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "../assets/stylesheets/layout.scss"
import Header from "./header"
import Footer from "./footer"

import {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import { HelmetDatoCms } from "gatsby-source-datocms";

const Layout = ({ location, title, seo, children }) => {  

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
      datoCmsHomePage {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  const isNotRootPath = location.pathname !== rootPath  

  return (
    <ThemeProvider theme={theme}> 
      <HelmetDatoCms
        seo={seo}        
        favicon={data.datoCmsSite.faviconMetaTags}        
      >        
        <meta name="author" content="Indus Chadha"></meta>
      </HelmetDatoCms>
      {isNotRootPath && 
        <Header data-datocms-noindex/>
      }
      <main style={{background:"#f4f4f4"}}>{children}</main>      
      <Footer/>
    </ThemeProvider>
  )
}

export default Layout
