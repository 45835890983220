import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import {Container, Row, Col} from 'reactstrap'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import Link from './link';
import styled from "styled-components"

const Footer = styled.footer`
  padding: 3rem 0;
  background: #f8f9fa;
  a, a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.5);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a, a:hover {
        color: inherit;
      }
    }
  }
`

let SocialLink = ({Icon, host, handle}) => (
  <Link to={host + handle} className="mr-2">
    <Icon size={30}/>
  </Link>
)

const FooterComponent = () => { 

  const data = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsHomePage {        
        bioNode {
          childMarkdownRemark {
            html
          }
        }
        twitter
        facebook
        instagram
        headshot {
          fluid(imgixParams: {w: "800", h: "400"}) {            
            ...GatsbyDatoCmsSizes
          }
        }
      }
    }
  `).datoCmsHomePage

  return (
    <Footer data-datocms-noindex>
      <Container className="d-grid gap-2">
        <Row className="align-items-center">
          <Col xs="6" sm="6" md="3" className="mx-auto order-md-5">
            <Img fluid={data.headshot.fluid} className="rounded-circle" imgStyle={{ objectFit: 'contain' }}/>
          </Col>
          <Col xs="12" sm="12" md="9">            
            <div className="lead" 
              dangerouslySetInnerHTML={{
                __html: data.bioNode.childMarkdownRemark.html,
              }}>
            </div>
          </Col>                      
        </Row>
        <Row>          
          <Col className="col-12 text-center">            
            <SocialLink Icon={FaFacebook} host="https://facebook.com/" handle={data.facebook} />
            <SocialLink Icon={FaInstagram} host="https://instagram.com/" handle={data.instagram}/>
            <SocialLink Icon={FaTwitter} host="https://twitter.com/" handle={data.twitter}/>
          </Col>          
        </Row>
        <Row className="py-5">
          <Col className="col-12 text-center lead">
            © {new Date().getFullYear()}, all rights reserved.        
          </Col>
        </Row>
      </Container>
    </Footer>
  )
}

export default FooterComponent
